import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";

export const getClientList = createAsyncThunk(
  "client/Listing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getAgentList = createAsyncThunk(
  "agent/Listing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        records: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleAgent = createAsyncThunk(
  "agent/SingleList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent/${payload.id}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        record: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleClientList = createAsyncThunk(
  "client/singlePost",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createClient = createAsyncThunk(
  "client/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Added Successfully",
        })
      );
      dispatch(getClientList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createAgent = createAsyncThunk(
  "agent/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Agent Added Successfully",
        })
      );
      dispatch(getAgentList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const pdfmanipluate = createAsyncThunk(
  "pdf/manipluate",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/client/account/upload_pdf/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Pdf manipluated Successfully",
        })
      );
      alert(res.document_url);

      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const updateClient = createAsyncThunk(
  "client/update",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.put(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Updated Successfully",
        })
      );
      dispatch(getClientList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const updateAgent = createAsyncThunk(
  "client/update",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.put(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Agent Updated Successfully",
        })
      );
      dispatch(getAgentList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

//   export const getSingleClient = createAsyncThunk(
//     "clients/Listing/1",
//     async (payload, { rejectWithValue, dispatch }) => {
//       try {
//         dispatch(startLoading());
//         let url = `/agent/client/1?${buildQueryString(payload)}`;
//         let res = await API.get(config.barong)(url);
//         dispatch(stopLoading());
//         return {

//         };
//       } catch (e) {
//         dispatch(stopLoading());
//         dispatch(errorToastSelector(e, "error"));
//         return rejectWithValue(e);
//       }
//     }
//   );
