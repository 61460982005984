import {
  Dashboard,
  Login,
  ChangePassword,
  ResetPassword,
  Earn,
  CreateAcount,
  VerifyEmail,
  Identity,
  Portfolio,
  VerifyIdentity,
  EmailConfirmation,
  Landing,
  LogoutCompo,
  Client,
} from "./lazyRoute";
import {
  sidebarRouteObj,
  publicRouteObj,
  primaryRoutes,
} from "../staticObjects/routing";


// Public routing array

const {
  login,
  changePassword,
  resetPassword,
  createAccount,
  emailVerify,
  emailConfirmation,
  identity,
  verifyIdentity
} = publicRouteObj;

const {
  authLanding,
  logout,
} = primaryRoutes;

export const commonRoutesData = [
];

export const publicRoutesData = [
  {
    path: primaryRoutes.landing,
    component: <Landing />,
  },
  {
    path: login,
    component: <Login />,
  },

  {
    path: `${changePassword}/:id`,
    component: <ChangePassword />,
  },
  {
    path: resetPassword,
    component: <ResetPassword />,
  },
  {
    path: createAccount,
    component: <CreateAcount />,
  },

  {
    path: emailVerify,
    component: <VerifyEmail />,
  },
  {
    path: `${emailConfirmation}/:id`,
    component: <EmailConfirmation />,
  },

  {
    path: identity,
    component: <Identity />,
  },
  {
    path: verifyIdentity,
    component: <VerifyIdentity />,
  },
];

// Private routing array

const {
  home,
  port,
  earn,
  client,
} = sidebarRouteObj;


export const privateRoutesData = [
  {
    path: ``,
    component: <Dashboard />,
  },
  {
    path: logout,
    component: <LogoutCompo />,
  },
  {
    path: authLanding,
    component: <Landing />,
  },
  {
    path: `${home}`,
    component: <Dashboard />,
  },
  {
    path: `${port}`,
    component: <Portfolio />,
  },
  {
    path: `${earn}`,
    component: <Earn />,
  },
  {
    path: `${client}`,
    component: <Client />,
  },

];
