import { lazy } from "react";

export const Mainlayout = lazy(() =>
  import("../Components/Layout/Mainlayout/Mainlayout")
);
export const Dashboard = lazy(() => import("../Components/Pages/Dashboard"));
export const LogoutCompo = lazy(() => import("../Components/Pages/Logout"));
export const PageNotFound = lazy(() =>
  import("../Components/Pages/PageNotFound/PageNotFound")
);
export const Login = lazy(() => import("../Components/Pages/Login"));
export const ChangePassword = lazy(() =>
  import("../Components/Pages/Login/PasswordSetting/ChangePassword")
);
export const ResetPassword = lazy(() =>
  import("../Components/Pages/Login/PasswordSetting/ResetPassword")
);
export const CreateAcount = lazy(() =>
  import("../Components/Pages/SignUp/CreateAcount")
);
export const VerifyEmail = lazy(() =>
  import("../Components/Pages/SignUp/VerifyEmail")
);
export const Verifiaction = lazy(() =>
  import("../Components/Pages/SignUp/Verifiaction")
);
export const VerificationCode = lazy(() =>
  import("../Components/Pages/SignUp/VerificationCode")
);
export const Identity = lazy(() =>
  import("../Components/Pages/SignUp/Identity")
);



export const VerifyIdentity = lazy(() =>
  import("../Components/Pages/SignUp/VerifyIdentity")
);
export const Portfolio = lazy(() =>
  import("../Components/Pages/Portfolio/Portfolio")
);
export const Earn = lazy(() => import("../Components/Pages/Earn"));

export const Client = lazy(() => import("../Components/Pages/Client"));

export const EmailConfirmation = lazy(() =>
  import("../Components/Pages/EmailConfirmation/index.jsx")
);


export const Landing = lazy(() =>
  import("../Components/Pages/Landing/index.jsx")
);




