import { createSlice } from "@reduxjs/toolkit";
import {
  getAllEarnings,
  getAllHistory,
  getEarnAssetsData,
  getEarnData,
  getReward,
} from "../../services/earnServices";
import { confirmModalVal } from "../../../helpers";

export const earnSlice = createSlice({
  name: "earn",
  initialState: {
    list: [],
    error: false,
    assetsList: [],
    earingList: [],
    earingTotal: 0,
    historyList: [],
    historyTotal: 0,
    confirmModalData: confirmModalVal,
  },
  reducers: {
    confirmModal: (state, action) => {
      state.confirmModalData = { ...state.confirmModalData, ...action.payload };
    },
    clearStateModal: (state, action) => {
      state.confirmModalData = { ...confirmModalVal };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEarnData.pending, (state) => {
        state.error = false;
      })
      .addCase(getEarnData.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(getEarnData.rejected, (state) => {
        state.list = [];
        state.error = true;
      })

      .addCase(getReward.pending, (state) => {
        state.error = false;
      })
      .addCase(getReward.rejected, (state) => {
        state.error = true;
      })

      .addCase(getEarnAssetsData.pending, (state) => {
        state.error = false;
      })
      .addCase(getEarnAssetsData.fulfilled, (state, action) => {
        state.assetsList = action.payload;
      })
      .addCase(getEarnAssetsData.rejected, (state) => {
        state.data = [];
        state.error = true;
      })

      .addCase(getAllEarnings.pending, (state) => {
        state.error = false;
      })
      .addCase(getAllEarnings.fulfilled, (state, action) => {
        state.earingList = [...action.payload.list];
        state.earingTotal = action.payload.total;
      })
      .addCase(getAllEarnings.rejected, (state) => {
        state.error = true;
      })

      .addCase(getAllHistory.pending, (state) => {
        state.error = false;
      })
      .addCase(getAllHistory.fulfilled, (state, action) => {
        state.historyList = [...action.payload.list];
        state.historyTotal = action.payload.total;
      })
      .addCase(getAllHistory.rejected, (state) => {
        state.error = true;
      });
  },
});

export default earnSlice.reducer;
export const { confirmModal, clearStateModals } =
  earnSlice.actions;
